import { template as template_bd223dc9374a4057b2228ebf93fd93d7 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
const FKCol = template_bd223dc9374a4057b2228ebf93fd93d7(`
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCol;

import { template as template_ff4b8cff48bc454486cc290f8ec1c4f6 } from "@ember/template-compiler";
const FKLabel = template_ff4b8cff48bc454486cc290f8ec1c4f6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_1bb029be85b141a68a0f68e2c218b693 } from "@ember/template-compiler";
const FKText = template_1bb029be85b141a68a0f68e2c218b693(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
